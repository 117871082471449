<template>
  <div>
    <van-nav-bar
      title="商品详情"
      left-arrow
      :fixed="true"
      @click-left="Close"
    />
    <!--  -->
    <div class="goods" v-if="goods != null">
      <div style="width: 100%">
        <van-swipe class="goods-swipe" @change="onChange">
          <van-swipe-item v-for="(thumb, index) in goods.images" :key="thumb">
            <!-- <img :src="thumb" @click="getImg(goods.images, index)" /> -->
            <van-image
              :src="thumb"
              style="width: 100vw; height: 100vw"
              @click="getImg(goods.images, index)"
              fit="cover"
            >
            </van-image>
          </van-swipe-item>
          <template #indicator>
            <div v-if="goods.images != null" class="custom-indicator">
              {{ current + 1 }}/{{ goods.images.length }}
            </div>
          </template>
        </van-swipe>
        <van-cell-group>
          <!--商品描述-->
          <van-cell>
            <template slot="title">
              <span class="goods-p1"
                ><img
                  :src="goods.platform_icon"
                  style="width: 15px; height: 15px"
                />
                {{ goods.title }}</span
              >
            </template>
          </van-cell>
          <!--价格 原价-->
          <van-cell>
            <div class="goods-price">
              <van-col span="18">
                <span>券后价</span
                ><span style="font-size: 28px; margin-left: 5px">{{
                  goods.discount_price
                }}</span></van-col
              >
              <van-col span="6">
                <span>下单返</span
                ><span>{{
                  goods.fl_commission ? goods.fl_commission : 0
                }}</span></van-col
              >
            </div>
            <div class="goods-oriprice" style="margin-top: 15px">
              <van-col span="18">
                <span>原价</span
                ><span
                  style="
                    font-size: 17px;
                    margin-left: 5px;
                    font-color: rgba(153, 153, 153, 1);
                  "
                  >{{ goods.price }}</span
                ></van-col
              >
              <van-col span="6">
                <span>已售</span><span>{{ goods.month_sales }}</span></van-col
              >
            </div>
          </van-cell>

          <!--优惠卷-->
          <van-cell style="width: 99%">
            <div class="outer6" @click="ISLogin(1)">
              <div>
                <van-col style="margin-left: 30px; margin-top: 30px"
                  ><span
                    style="
                      color: rgba(168, 0, 0, 1);
                      font-size: 14px;
                      margin-top: 10px;
                    "
                    >¥</span
                  ><span style="color: rgba(168, 0, 0, 1); font-size: 28px">{{
                    goods.coupon_money
                  }}</span></van-col
                >
                <van-col style="margin-top: 10px; margin-left: 10px">
                  <span
                    style="
                      display: block;
                      color: rgba(168, 0, 0, 1);
                      font-size: 14px;
                    "
                    >优惠券</span
                  >
                  <span style="color: rgba(153, 153, 153, 1); font-size: 12px"
                    >{{ replaceStr(goods.coupon_starttime) }}-{{
                      replaceStr(goods.coupon_endtime)
                    }}</span
                  >
                </van-col>
                <van-col>
                  <!--- <div class="btndiv" @click="openUrl(goods.coupon_click_url)"> 
                    <span style="dispaly:block;    display: block; goods.coupon_click_url
                      margin-left: 18px;
                      padding-top: 10px;
                  }" >立即</span>
                    <span style=" margin-left: 18px;"> 领取</span>
              </div>-->
                </van-col>
              </div>
            </div>
          </van-cell>
          <!------>
          <!--推荐理由-->
          <!-- <van-cell>
       <van-col >推荐理由：{{ goods.reason }}<a style="font-color:rgba(255, 255, 255, 1)">复制文案</a></van-col>
     </van-cell>-->
          <!--推荐理由-->
          <!--:disable = "editable" -->
          <van-cell v-if="goods.recommend != null">
            <span class="word9">推荐理由:</span>
            <span class="word10" id="copy-reason">
              {{ goods.recommend }}
            </span>
            <span
              style="
                font-size: 12px;
                font-family: PingFangSC-Regular;
                color: rgba(195, 101, 0, 1);
                line-height: 17px;
              "
              @click="copyWaybillNo('#copy-datacontent')"
              :data-clipboard-text="goods.recommend"
              :id="'copy-datacontent'"
              >复制文案</span
            >
          </van-cell>
        </van-cell-group>

        <van-cell-group class="goods-cell-group">
          <van-cell>
            <template slot="title">
              <van-col span="3"
                ><span>
                  <img
                    :src="goods.shop_logo"
                    style="width: 25px; height: 25px" /></span
              ></van-col>
              <van-col span="21"
                ><span
                  class="van-cell-text"
                  style="display: block; height: 18px; font-size: 18px"
                >
                  {{ goods.shop_name }}</span
                >
                <span>
                  <!--<img style="margin-top:1px;" src="#"/>-->
                  <!---value="进入店铺"  is-link-->
                </span></van-col
              >
              <!-- <van-tag class="goods-tag" type="danger">官方</van-tag>-->
            </template>
          </van-cell>
          <!--卖家服务-->

          <van-cell
            class="goods-express"
            :border="false"
            v-if="goods.dsr_info != null"
          >
            <van-col span="8"
              >宝贝描述
              <span style="color: rgba(218, 11, 6, 1)">{{
                goods.dsr_info.descriptionMatch != null
                  ? goods.dsr_info.descriptionMatch
                  : "0"
              }}</span></van-col
            >
            <van-col span="10"
              >卖家服务
              <span style="color: rgba(218, 11, 6, 1)">{{
                goods.dsr_info.serviceAttitude != null
                  ? goods.dsr_info.serviceAttitude
                  : "0"
              }}</span></van-col
            >
            <van-col span="6"
              >物流服务
              <span style="color: rgba(218, 11, 6, 1)">{{
                goods.dsr_info.deliverySpeed != null
                  ? goods.dsr_info.deliverySpeed
                  : "0"
              }}</span></van-col
            >
          </van-cell>
        </van-cell-group>
        <!--猜你喜欢-->

        <div style="margin-top: 5px; padding: 10px 20px; background: #ffffff">
          <img style="width: 100%; height: 30px" src="/img/youlike.png" alt />
        </div>
        <!--<likeList  :deviceType="deviceType1"  :deviceValue="deviceValue1"  :type="1"/>-->
        <div>
          <div class="goods-container">
            <div v-for="item in likegoods" :key="item.id" :name="item.id">
              <div class="main-goodsList">
                <div class="left-goodsList">
                  <div>
                    <van-image
                      :src="item.picUrl"
                      style="
                        margin-left: 1vw;
                        margin-right: 1vw;
                        width: 44vw;
                        height: 44vw;
                      "
                      @click="showDetail(item)"
                      fit="cover"
                    ></van-image>
                  </div>
                  <div>
                    <!--  <img
                        :src="item.platform_icon"
                        style="width: 15px; height: 15px"
                      /> -->
                    <div>
                      <span class="p1" @click="showDetail(item)">
                        {{
                          item.name.length > 10
                            ? item.name.substr(0, 8) + "..."
                            : item.name
                        }}</span
                      >
                    </div>
                  </div>
                  <div style="height: 20px">
                    <span class="info-goodsList">{{ item.shop_name }}</span>
                  </div>
                  <div>
                    <span class="word4-goodsList">¥{{ item.priceDown }}</span>
                    <span
                      class="info-goodsList"
                      style="text-decoration: line-through"
                      >¥{{ item.priceUp }}</span
                    >
                    <span class="info-goodsList" style="margin: 6px 0 0 7px"
                      >已售{{ item.saleNum }}</span
                    >
                  </div>
                  <!-- <div style="height: 30px">
                    <span class="span1-goodsList">
                      <span class="word10-goodsList">券</span>
                      <span class="word10-goodsList"
                        >¥{{ item.couponMoney }}</span
                      >
                    </span>
                    <span
                      style="
                        font-size: 10px;
                        color: rgba(255, 0, 0, 1);
                        background-color: rgba(254, 240, 240, 1);
                        margin-left: 20px;
                      "
                      >分享赚¥{{ item.shareMoney }}</span
                    >
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <van-goods-action fixed="true" @touchmove.prevent>
          <van-goods-action-icon icon="home-o" @click="Closeback">
            首页
          </van-goods-action-icon>

          <van-goods-action-icon
            :icon="icon"
            @click="ISLogin(2)"
            :color="color"
          >
            收藏
          </van-goods-action-icon>
          <!-- <van-goods-action-button
            type="warning"
            color="rgba(49, 49, 49, 1)"
            @click="ISLogin(0)"
          >
            分享赚¥{{ goods.makeup }}
          </van-goods-action-button> -->
          <!--   @click="$router.push({name:'goodshare',query: {'Id':goods.item_id,'platformId':'1'}})"-->
          <!-- ¥{{ goods.makeup ? goods.makeup : 0 }} -->
          <van-goods-action-button type="danger" @click="ISLogin(1)"
            >去购买</van-goods-action-button
          >
        </van-goods-action>
      </div>
      <van-dialog
        use-slot
        v-model="isShow"
        confirm-button-open-type="getUserInfo"
        theme="round-button"
        confirm-button-text="前往淘宝授权"
        @confirm="Empower"
        :overlay="true"
        :close-on-click-overlay="true"
      >
        <div>
          <div class="info">
            <span>
              <img referrerpolicy="no-referrer" src="img/weitu.png" />
            </span>
            <span>
              <img
                class="icon2"
                referrerpolicy="no-referrer"
                src="img/fangxiang.png"
            /></span>
            <span style="margin-left: 10px"
              ><img referrerpolicy="no-referrer" src="img/taobao.png"
            /></span>
          </div>
          <div class="word14">申请淘宝授权</div>
          <div class="info5">应淘宝要求，获得返佣前请先进行官方授权！</div>
        </div>
      </van-dialog>
      <!--不需要授权:confirm="$router.push(goods.click_url)"-->
      <van-dialog
        use-slot
        v-model="isoauthShow"
        confirm-button-open-type="getUserInfo"
        theme="round-button"
        :confirm-button-text="text"
        bind:getuserinfo="getUserInfo"
        @confirm="openUrl"
        :overlay="true"
        :close-on-click-overlay="true"
      >
        <div>
          <div class="info1">
            <div class="info2">
              <span>{{ tilteName }}</span>
            </div>
            <div>
              <span>
                <img
                  class="label4"
                  style="margin-bottom: 20px"
                  referrerpolicy="no-referrer"
                  src="img/weitu.png"
                />
              </span>
              <span>
                <img
                  class="icon2"
                  style="margin-bottom: 30px"
                  referrerpolicy="no-referrer"
                  src="img/fangxiang.png"
              /></span>
              <span
                ><img
                  style="margin-left: 10px; margin-bottom: 20px"
                  class="label5"
                  referrerpolicy="no-referrer"
                  :src="picSrc"
              /></span>
            </div>
          </div>

          <div class="info6">
            <div>
              <span>返佣&nbsp;—&nbsp;—&nbsp;—&nbsp;¥{{ goods.makeup }}</span>
            </div>
            <div>
              <span
                >领券&nbsp;—&nbsp;—&nbsp;—&nbsp;¥{{ goods.coupon_money }}</span
              >
            </div>
          </div>
        </div>
      </van-dialog>
      <!---是否登录-->
      <van-dialog
        use-slot
        v-model="isShowLogin"
        theme="round-button"
        confirm-button-text="请先去登录"
        :overlay="true"
        :close-on-click-overlay="true"
        @confirm="Login()"
      >
        <div>
          <div class="info">
            <span>
              <img referrerpolicy="no-referrer" src="img/weitu.png" />
            </span>
          </div>
          <div class="info6">
            <div>
              <span>返佣&nbsp;—&nbsp;—&nbsp;—&nbsp;¥{{ goods.makeup }}</span>
            </div>
            <div>
              <span
                >领券&nbsp;—&nbsp;—&nbsp;—&nbsp;¥{{ goods.coupon_money }}</span
              >
            </div>
          </div>
          <div class="info5">应官方要求，收藏分享下单前请先登录！</div>
        </div>
      </van-dialog>
    </div>
    <!--   -->
    <div v-else-if="goods == null" style="margin-top: 45%; text-align: center">
      <span
        style="color: #ccc5c5; font-size: 16px; font-family: PingFangSC-Regular"
        >商品已经下架了~</span
      >
    </div>
  </div>
</template>

<script>
// 收藏 clickLike
// import { clickLike } from "@/api/shopDetail2"; , cancelCollect
import { getGoodDetail, userCollect, TJPCollect } from "@/api/goods/goods";
import { deleteObj } from "@/api/collect/collect";
//import {geGuesstObj} from '@/api/juhe/juhe'
//import likeList from '@/views/mylike/index.vue'
import { ImagePreview, Toast } from "vant";
import { geGuesstObj } from "@/api/goods/goods";
import Clipboard from "clipboard";
export default {
  components: {
    //likeList,
  },
  data() {
    return {
      deviceValue: "IMEI",
      deviceType: "1",
      current: 0,
      isShow: false, //不需要授权
      isoauthShow: false, //需要授权
      goods: [],
      itemId: "",
      platformId: "",
      activityId: "",
      source: "",
      typeInfo: null,
      session: null,
      deviceType1: "IMEI",
      deviceValue1: "1",
      isShowLogin: false,
      color: "rgba(49, 49, 49, 1)",
      icon: "star-o",
      collect: false,
      loginInfo: null,
      empowerInfo: null,
      token: null,
      empowerB: null,
      phoneType: "isAndroid",
      collectId: "",
      text: "请前往淘宝购买",
      tilteName: "跳转淘宝",
      picSrc: "img/taobao.png",
      isshowLoading: true,
      likegoods: [],
      // loading: false,
      // finished: false,
      total: 0, //总共的数据条数
      page: {
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条
      },
    };
  },
  created() {
    this.getUser();
    // this.$toast('加载')
  },
  mounted() {
    //this.$store.commit('showLoading')
    //定义全局变量
    this.phoneType = this.getPhoneType();
    window.getInfo = this.getInfo;
    window.empower = this.empower;
    window.getGoodDetails = this.getGoodDetails;
    this.itemId = this.$route.query.id;
    this.platformId = this.$route.query.platformId;
    this.activityId = this.$route.query.activityId;
    this.source = this.$route.query.source;
    if (this.$route.query.type != null) {
      this.typeInfo = this.$route.query.type;
    }
    if (this.$route.query.session != null) {
      this.session = this.$route.query.session;
      //设置session
      this.$store.commit("setSession", this.session);
    }
    if (this.$route.query.token != null) {
      this.$store.commit("setToken", this.$route.query.token);
    }
    if (
      this.$route.query.deviceType != null &&
      this.$route.query.deviceValue != null
    ) {
      this.$store.commit(
        "setDevie",
        this.$route.query.deviceType,
        this.$route.query.deviceValue
      );
    }
    if (this.$route.query.token != null) {
      this.token = this.$route.query.token;
    }
    if (this.$route.query.deviceValue != null) {
      this.deviceValue1 = this.$route.query.deviceValue;
    }
    if (this.$route.query.deviceType != null) {
      this.deviceType1 = this.$route.query.deviceType;
    }
    if (this.$route.query.empower != null) {
      this.empowerInfo = this.$route.query.empower;
      this.$store.commit("setEmpower", this.$route.query.empower);
    }
    this.$store.commit("setUrl", window.location.href);
    this.getGoodDetails();
    this.getLikeGoods();
    //this.$store.commit('hidenLoading')
  },
  methods: {
    // 登录方
    //判断当前用户是否收藏此商品
    //轮播图变换
    swipeChange(index) {
      this.swipeCurrent = index;
    },
    getPhoneType() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        return "isIOS";
      }
      if (isAndroid) {
        return "isAndroid";
      }
    },
    getShareData() {
      this.$store.commit("setShareData", {
        valuePicUrl: this.goods.images,
        valueContent:
          this.goods.platform_id == "1" ? this.goods.tkl : this.goods.recommend,
        valueOpenUrl: this.goods.coupon_click_url,
        valueTiltle: this.goods.title,
        valueFormId: this.goods.platform_id,
        valueMakeup: this.goods.makeup,
        valuePrice: this.goods.discount_price,
        valueoldPrice: this.goods.price,
      });
    },
    getGoodDetails() {
      let params = {
        itemId: this.itemId || "12713923192", // 京东 3 100015384733 拼2 E9T2_RXLf5xmjv3xwfDbD2izYecJ8xcy_Jm1dtyaZT 1淘 12713923192
        platformId: this.platformId || "1",
        activityId: this.activityId || null,
        token: this.$route.query.bynToken,
      };
      // if (this.token != null) {
      //   params = {
      //     itemId: this.itemId,
      //     platformId: this.platformId,
      //     token: this.token,
      //   };
      // }
      getGoodDetail(params)
        .then((data) => {
          if (data.data.code == 1) {
            Toast(data.data.message);
          }
          if (data.data.code == 0) {
            console.log(data.data.data.data, "商品详情");
            // console.log(this.goods = data.data, "商品详情");
            // 想要的数据
            this.goods = data.data.data.data;
            // this.goods.deep_link =
            //   "https://union-click.jd.com/jdc?e=1001691378&p=JF8BAWUJK1olXg4KVFtdCkgQAl8IGloQVA4CXFZcCU0fAF9MRANLAjZbERscSkAJHTRQRA1CCVkdDwtCWhVLHTdNTwcKBENeCVAfUg8bA24JHlIdXQ4KVV9bAEgAQ2N8YitwKXUCCyokUhtgcAhJbSJtBWJhJBg-ahFxYSZ0XTteLnVlDjYUaykXdA0JcxtjP1twNhclTitfewZxaA5CKllqNyouawhjAQtQcwRxLmFJCj06VShLZgt8SC9MOkR0PS4-cThoVF9sEwB-JVMLXTsGfjVVWmtsW15jOlhEWFJtCXsXAm4LGlMSXgQKZF4zCCUUAmoLdVolXQYyFTBdC0gWBGkOGmsXXAcAV1hUAU0UM28PH14UWgQBVl5VDUonA2cPKw5LAlBXDA4CTR9FWl84K1glXQcDVFlVCEkRCl84G1glXjZcOl8OCEtABj9cdQYXGgFZDRYcZkMWA2wIElsTbQQDVVxfOHs";
            if (this.goods == null) {
              return;
            }
            if (this.goods.collectId != null) {
              this.icon = "star";
              this.color = "#f44";
            } else {
              this.icon = "star-o";
              this.color = "#1f0a00";
            }
          } else {
            console.log(4444);
          }
        })
        .catch(() => {});
      // let ispeed = Math.floor(-this.scrollTop / 5);
      // document.documentElement.scrollTop = document.body.scrollTop =
      //   this.scrollTop + ispeed;
    },
    userCollect() {
      //source 必应鸟 1 自营店
      //platformId :平台ID 1 淘宝 2 拼多多 3京东
      //relationIds : 关联ID：商品类型为商品ID，商铺类型为店铺ID
      //type:类型1、商品，2、商铺

      if (this.icon == "star") {
        this.cancelUserCollect();
      } else {
        let params = {
          id: "",
          tenantId: null,
          delFlag: null,
          createTime: null,
          updateTime: null,
          type: "1",
          userId: "",
          relationIds: [this.itemId],
          goodsSpu: null,
          shopInfo: null,
          userInfo: null,
          source: "0", //0 鸟
          platformId: this.platformId,
          couponMoney: this.goods.coupon_money,
          spuName: this.goods.title,
          pics: this.goods.images == null ? [] : this.goods.images,
          shopName: this.goods.shop_name,
          discountPrice: this.goods.discount_price,
          price: this.goods.price,
          monthSales:
            this.goods.month_sales === "10万+"
              ? 100000
              : +this.goods.month_sales,
          tkRate: this.goods.tk_rate,
        };
        console.log(params, "params");
        const data = {
          type: "1",
          relationId: this.itemId || "597649283190",
        };
        console.log(data, "data2");
        userCollect(data)
          .then((res) => {
            if (res.data.code === 0) {
              Toast("收藏成功");
              // this.icon = "star";
              // this.color = "#f44";
              this.getGoodDetails();
            } else {
              Toast(res.data.msg);
              this.$goBack();
            }
          })
          .catch(() => {
            Toast("系统异常");
          });
      }
    },

    cancelUserCollect() {
      let arr = [];
      arr.push(this.goods.collectId);
      deleteObj(arr)
        .then((res) => {
          if (res.data.code == 0) {
            Toast("取消收藏");
            this.icon = "star-o";
            this.color = "#1f0a00";
          }
        })
        .catch(() => {
          Toast("系统错误");
        });
    },
    onChange(index) {
      this.current = index;
    },
    getUserInfo(event) {
      console.log(event.detail);
    },
    getImg(images, index) {
      ImagePreview({
        images: images,
        showIndex: true,
        loop: false,
        startPosition: index,
      });
    },
    //购买
    openUrl() {
      if (this.phoneType == "isIOS") {
        if (this.goods.platform_id == "1") {
          // 淘宝
          window.webkit.messageHandlers.empowerURl.postMessage(
            this.goods.coupon_click_url
          );
          window.webkit.messageHandlers.close.postMessage("openurl");
        } else {
          window.webkit.messageHandlers.empowerURl.postMessage(
            this.goods.deep_link
          );
          window.webkit.messageHandlers.close.postMessage("openThirdUrl");
        }
      }
      if (this.phoneType == "isAndroid") {
        if (this.goods.platform_id == "2") {
          // window.location.href = this.goods.coupon_click_url;
          window.android.deeplink(this.goods.deep_link);
        } else if (this.goods.platform_id == "3") {
          window.android.deeplink(this.goods.deep_link);
        } else {
          window.android.empowerURL(this.goods.coupon_click_url);
        }
        // window.android.empowerURL(this.goods.coupon_click_url);
      }
    },
    // 返回原生
    Close() {
      if (this.typeInfo != null) {
        this.$router.back(-1);
      } else {
        if (this.phoneType == "isIOS") {
          window.webkit.messageHandlers.close.postMessage("up");
        }
        if (this.phoneType == "isAndroid") {
          window.android.close();
        }
      }
    },
    // 返回首页
    Closeback() {
      if (this.phoneType == "isIOS") {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (this.phoneType == "isAndroid") {
        window.android.close();
      }
    },
    //判断是否有session
    ISLogin(val) {
      if (!this.session) {
        this.session = "1";
      }
      if (!this.loginInfo) {
        this.loginInfo = "2";
      }
      //登录操作
      if (this.session == null && this.loginInfo == null) {
        this.isShowLogin = true;
      } else {
        //登录之后，0 分享 1 下单 2 收藏
        if (val == 1 || val == 0) {
          //淘宝授权
          if (this.goods.platform_id == "1") {
            //没有授权(百川授权)
            if (this.empowerInfo != "1") {
              this.isShow = true;
            } else {
              if (this.empowerInfo == "1") {
                if (this.phoneType == "isIOS") {
                  if (this.goods.need_oauth) {
                    window.webkit.messageHandlers.empowerB.postMessage(
                      this.goods.oauth_url
                    );
                    //window.webkit.messageHandlers.close.postMessage('empower');
                  } else {
                    //授权之后
                    if (val == 0) {
                      this.getShareData();
                      this.$router.push({
                        name: "goodshare",
                        query: {
                          Id: this.goods.item_id,
                          platformId: this.platformId,
                        },
                      });
                    } else {
                      this.isoauthShow = true;
                    }
                  }
                }
                if (this.phoneType == "isAndroid") {
                  if (this.goods.need_oauth) {
                    window.android.empowerB(this.goods.oauth_url);
                  } else {
                    if (val == 0) {
                      this.getShareData();
                      this.$router.push({
                        name: "goodshare",
                        query: {
                          Id: this.goods.item_id,
                          platformId: this.platformId,
                        },
                      });
                    } else {
                      this.isoauthShow = true;
                    }
                  }
                }
              }
            }
            //京东
          } else if (this.goods.platform_id == "3") {
            if (val == 0) {
              this.getShareData();
              this.$router.push({
                name: "goodshare",
                query: { Id: this.goods.item_id, platformId: this.platformId },
              });
            } else {
              this.picSrc = "/img/jingdong.png";
              this.text = "请前往京东购买";
              this.tilteName = "跳转京东";
              this.isoauthShow = true;
            }
            //url = url.substr(0, url.indexOf("https"));   //?之前主地址
            // url = url.substr(url.indexOf("https") + 1);   //？之后参数路径
            // alert(url)
            //window.open(this.goods.coupon_click_url);
            //拼多多
          } else {
            //window.location.href=this.goods.mobile_short_url
            if (val == 0) {
              this.getShareData();
              this.$router.push({
                name: "goodshare",
                query: { Id: this.goods.item_id, platformId: this.platformId },
              });
            } else {
              this.picSrc = "/img/pinduoduo.png";
              this.text = "请前往拼多多购买";
              this.tilteName = "跳转拼多多";
              this.isoauthShow = true;
            }
          }
        } else {
          //收藏操作
          this.clickLike();
          console.log("收藏操作");
          // this.userCollect();
          // this.getGoodDetails();
        }
      }
    },
    // 收藏方法 收藏逻辑
    clickLike() {
      // 收藏
      let params = {
        //  title: this.goods.title,
        //  couponMoney: this.goods.coupon_money,
        //  couponMoneyText: this.goods.coupon_money_text,
        //  couponExplainMoney: this.goods.coupon_explain_money,
        //  price: this.goods.price,
        //  discountPrice: this.goods.discount_price,
        //  coverImage: this.goods.cover_image,
        platformId: this.goods.platform_id,
        itemId: this.goods.item_id,
      };
      TJPCollect(params).then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          //  console.log(res);
          this.getGoodDetails();
          if (res.data.data) {
            if (res.data.data == "收藏成功") {
              this.icon = "star";
              this.color = "#f44";
            } else {
              this.icon = "star-o";
              this.color = "#1f0a00";
            }
            Toast(res.data.data);
          }
        }
      });
      // // 为null没有收藏
      // let params = {
      //   type: "1",
      //   relationId: this.goods.item_id,
      // };
      // if (!this.goods.collectId) {
      //   //点击收藏
      //   params.source = "1";
      //   clickLike(params).then((res) => {
      //     if (res.data.code === 0) {
      //       console.log(res);
      //       localStorage.setItem("item_id", res.data.data.id);
      //       this.getGoodDetails();
      //     }
      //   });
      // } else {
      //   //取消收藏
      //   params.id = localStorage.getItem("item_id"); //取消时传的值
      //   clickLike(params).then((res) => {
      //     if (res.data.code === 0) {
      //       console.log(res);
      //       localStorage.removeItem("item_id");
      //       this.getGoodDetails();
      //     }
      //   });
      // }
    },
    //登录
    Login() {
      if (this.phoneType == "isIOS") {
        //如果客户没有登录，确保客户去登录
        if (this.session == null) {
          window.webkit.messageHandlers.close.postMessage("login");
        }
        //  window.webkit.messageHandlers.share.postMessage('zifuchuan');   分享  opeurl 打开url
      }
      if (this.phoneType == "isAndroid") {
        if (this.session == null) {
          //登录
          window.android.login();
        }
      }
    },
    // 登录-session 原生调用
    getInfo(val) {
      let arr = val.split("&");
      if (arr.length == 2) {
        this.loginInfo = arr[0];
        this.token = arr[1];
        this.$store.commit("setSession", this.loginInfo);
        this.$store.commit("setToken", this.token);
        //this.getGoodDetails();
        // window.webkit.messageHandlers.empowerB.postMessage(this.goods.need_oauth);
        //if(this.goods.need_oauth)
        // {
        // window.webkit.messageHandlers.empowerB.postMessage(this.goods.oauth_url);
        //}
      } else {
        Toast("token,session不能为空");
        return;
      }
      //window.webkit.messageHandlers.close.postMessage('login');
      //alert(this.loginInfo)
    },
    //百川 授权-1  调用
    empower(val) {
      this.empowerInfo = val;
      if (val == "1") {
        this.getGoodDetails();
        if (this.phoneType == "isIOS") {
          if (this.goods.need_oauth) {
            window.webkit.messageHandlers.empowerB.postMessage(
              this.goods.oauth_url
            );
          } else {
            window.webkit.messageHandlers.empowerURl.postMessage(
              this.goods.coupon_click_url
            );
            window.webkit.messageHandlers.close.postMessage("openurl");
          }
        }
        if (this.phoneType == "isAndroid") {
          if (this.goods.need_oauth) {
            window.android.empowerB(this.goods.oauth_url);
          } else {
            window.android.empowerURl(this.goods.coupon_click_url);
          }
        }
      } else {
        Toast("百川授权失败，请重新授权");
      }
      //用百川打开详情中的oauth_url,进行跟必应鸟授权
    },

    //原生调用 必应鸟
    getempowerB(val) {
      if (val == 1) {
        if (this.phoneType == "isIOS") {
          window.webkit.messageHandlers.empowerURl.postMessage(
            this.goods.coupon_click_url
          );
        }
        if (this.phoneType == "isAndroid") {
          //授权
          window.android.empowerURl(this.goods.coupon_click_url);
        }
      } else {
        Toast("授权失败,请重新授权");
      }
    },
    //百川授权 H5调用 原生
    Empower() {
      if (this.phoneType == "isIOS") {
        window.webkit.messageHandlers.close.postMessage("empower");
      }
      if (this.phoneType == "isAndroid") {
        //授权
        window.android.empower();
      }
    },
    copyWaybillNo(id) {
      let clipboard = new Clipboard(id);
      this.$nextTick(() => {
        clipboard.on("success", function () {
          Toast("复制成功");
          clipboard.destroy();
        });
        clipboard.on("error", function () {
          console.log("您的浏览器不支持点击复制");
          clipboard.destroy();
        });
      });
    },
    getLikeGoods() {
      //let params1 ={'deviceType':'IMEI','deviceValue':'1'}
      let params1 = {
        deviceType: this.deviceType1,
        deviceValue: this.deviceValue1,
      };
      const object2 = Object.assign(
        { page: this.page.currentPage, pageSize: this.page.pageSize },
        params1
      );
      geGuesstObj(object2)
        .then((re) => {
          if (re.data.code === 0) {
            this.likegoods = re.data.data.records;
            let newArr = [];
            for (let i = 0; i < this.likegoods.length; i++) {
              if (this.likegoods[i].recommendedType == "精选好物") {
                if (this.likegoods[i].advType == 0) {
                  newArr.push(this.likegoods[i]);
                }
              }
            }
            this.likegoods = newArr;
          }
          //如果列表数据条数>=总条数，不再触发滚动加载
          // this.loading = false;
          // //alert(JSON.stringify(this.total))
          // if (rows == null || rows.length === 0) {
          //   // 加载结束
          //   this.finished = true;
          //   return;
          // }
          // if (this.likegoods.length >= 200) {
          //   this.finished = true;
          // }
          // //this.goods=res.data.data.data.data
          // //alert(JSON.stringify(this.goods))
          // if(this.likegoods.length <= re.data.data.total) {
          //   this.finished = true;
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    showDetail(item) {
      console.log(11111111);
      this.$router.push({
        path: "/itemDetail",
        query: {
          id: item.id,
          first: 0,
        },
      });
      // console.log(item);
      // this.itemId = item.item_id;
      // this.platformId = item.platform_id;
      // this.current = 0;
      // this.getGoodDetails();
      // this.likegoods = [];
      // this.getLikeGoods();
      // 返回顶部
      // let ispeed = Math.floor(-this.scrollTop / 5);
      // document.documentElement.scrollTop = document.body.scrollTop =
      //   this.scrollTop + ispeed;
    },
    // onLoad() {
    //   console.log(1111);
    //   // this.page.currentPage++;
    //   // this.getLikeGoods();
    // },
    replaceStr(str) {
      if (str != null) {
        var reg = new RegExp("-", "g"); //g,表示全部替换
        return str.replace(reg, ".").split(" ")[0];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.goods {
  padding-bottom: 50px;
  padding-top: 50px;

  &-swipe {
    img {
      width: 100%;
      display: block;
    }
  }
  .back {
    background: url(/assets/编组8.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -moz-background-size: 100% 100%;
  }
  .flex-action {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .flex-cur {
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    flex-direction: column;
  }
  .flex-cur img {
    width: 50px;
    height: auto;
  }

  .shop-list-box {
    /* padding:0 10px; */
    background: white;
  }

  .shop-detail-img {
    padding-right: 10px;
  }
  .shop-detail-img img {
    width: 2rem;
    height: auto;
  }
  .shop-detail-list {
    padding-top: 10px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.01rem solid #efeff4;
  }
  .shop-detail-intro {
    flex: 1;
    height: 100%;
  }
  &-title {
    font-size: 16px;
  }

  &-price {
    color: #f44;
  }

  &-express {
    color: #999;
    font-size: 12px;
    padding: 5px 15px;
  }

  &-cell-group {
    margin: 15px 0;

    .van-cell__value {
      color: #999;
    }
  }

  &-tag {
    margin-left: 5px;
  }
}
.info {
  width: 100%;
  display: block;
  overflow-wrap: break-word;
  text-align: center;
  align-self: center;
  margin-top: 20px;
}
.info1 {
  width: 100%;
  display: block;
  overflow-wrap: break-word;
  text-align: center;
  align-self: center;
  background-color: #f70035;
}
.info2 {
  //width: 100%;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: PingFangSC-Regular;
  line-height: 22px;
  text-align: center;
  align-self: center;
  //margin-bottom: 20px;
  padding: 15px;
}

.icon2 {
  width: 23px;
  height: 23px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.info5 {
  width: 100%;
  display: block;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 11px;
  letter-spacing: -0.30000001192092896px;
  font-family: PingFangSC-Regular;
  line-height: 16px;
  text-align: center;
  align-self: center;
  margin-top: 11px;
}
.info6 {
  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: center;
  align-self: center;
  padding: 10px;
}
.word14 {
  width: 100%;
  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  letter-spacing: -1.2000000476837158px;
  font-family: PingFangSC-Regular;
  line-height: 22px;
  text-align: center;
  align-self: center;
  margin-top: 9px;
}

.outer6 {
  // background: url(/img/quan.png) no-repeat;
  background-repeat: no-repeat;
  background-size: 96% 96%;
  -moz-background-size: 96% 96%;
  background-image: url(/img/quan.png);
  //background-size: cover;
  align-self: center;
  margin-top: 10px;
  justify-content: center;
  align-items: flex-end;
  margin-left: 2%;
  margin-right: 2%;
  width: 100%;
  height: 80px;
}
.goods-p1 {
  //text-align: center;
  //height:34px;
  font-size: 17px;
  margin-left: 5px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.btndiv {
  background-image: url(/img/btn.png);

  margin-top: 10px;
  margin-left: 60px;
  justify-content: center;
  align-items: flex-end;
  height: 80px;
  width: 100%;
}
.goods .custom-indicator {
  position: absolute;
  right: 16px;
  bottom: 10px;
  font-size: 11px;
  background: rgba(0, 0, 0, 0.3);
  height: 22px;
  width: 34px;
  border-radius: 11px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.groundimg {
  background: url(/img/youlike.png) 100% no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 96% 96%;
  height: 30px;
  margin-left: 2%;
  margin-right: 2%;
}
.goods-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  // flex-direction: column;
}
.left-goodsList,
.right-goodsList {
  margin-top: 10px;
  margin-left: 1vw;
  margin-right: 1vw;
  padding: 1vw;
  width: 46vw;
  // height: 61px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  // height: 100%;
}
.right-goodsList {
  float: right;
}
.info-goodsList {
  overflow-wrap: break-word;
  color: rgb(204, 197, 197);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 15px;
  text-align: left;
  margin-left: 5px;
}
.word4-goodsList {
  overflow-wrap: break-word;
  color: rgba(168, 0, 0, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
  margin-left: 5px;
}

.word10-goodsList {
  line-height: 10px;
  margin-left: 5px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 10px;
  font-family: PingFangSC-Regular;
}
.p1 {
  //text-align: center;
  //height:34px;
  font-size: 17px;
  margin-left: 5px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 20px;
}
.span1-goodsList {
  margin-left: 5px;
  background: url(/img/youhui-1.png) no-repeat;
  background-size: 100% 100%;
  padding: 0 10px;
  display: flex;
  align-items: center;

  //	width: 46px;
  //height:18px;
  /* 
	使用% 设置宽高以适应父元素的大小
	因为可能得高度坍塌，使用padding-top=（原图高/原图宽）设置高度
	*/
  //background-size: cover;
  //background-position: center;
  /*
	使用 background-size: cover; 自适应铺满
	background-position: center; 兼容不支持上面语句的浏览器版本
	*/
  display: inline-block;
}
</style>
